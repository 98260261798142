import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "./rootState";
import { template } from "./template";
import { loading } from "./loading";

const store: StoreOptions<RootState> = {
  state: {
    version: "1.0.0", // a simple property
  },
  modules: {
    template,
    loading 
  }
};

export default new Vuex.Store<RootState>(store);
