import { GetterTree } from "vuex";
import { RootState } from "../rootState";
import {
  TemplateState,
} from "./state";

const getters: GetterTree<TemplateState, RootState> = {
  getTemplateInfo(state: TemplateState): TemplateState {
    return state;
  },

  getTest(state: TemplateState): string|null {
    return state.test;
  },

  typeDoc(state: TemplateState): any {
    return state.type;
  },
};

export default getters;
