enum Theme {
  DARK,
  LIGHT
}

enum DocType {
  CMND,
  CCCD,
  HDKT
}

export default {
  Theme,
  DocType,
};
