import { Module } from "vuex";
import getters from "./getters";
import mutations from "./mutations";
import { LoadingState } from "./state";
import { RootState } from "../rootState";

export const state = new LoadingState();

const namespaced = true;

export const loading: Module<LoadingState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
};
