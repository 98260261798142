export default {
  "selectedLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language "])},
  "title": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag and drop file into the box below or"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from your folder to upload."])}
  },
  "warnFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to upload file here or"])},
  "warnDrive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["insert Google Drive link below"])},
  "opDrive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or using Google Drive link here"])},
  "upDrive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ULR Google Drive Folder"])},
  "btnProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process data"])},
  "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data processed"])},
  "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image "])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type:"])},
  "loadData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data extracted"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download file "])},
  "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["©2021 The copyright belongs to AI Power"])}
}