import { ActionTree } from "vuex";
import { RootState } from "../rootState";
import { TemplateState } from "./state";

// import RepositoryFactory from "@/lib/https/repositoryFactory";
// import AuthRepository from "@/lib/https/authRepository";
// import { state } from ".";

const actions: ActionTree<TemplateState, RootState> = {
//   async loadTypeDoc() {
//       try {
//         const { type } = RepositoryFactory.getRepository<AuthRepository>(AuthRepository);
//         const res = await type();
//         console.log(res);
//         // this.commit('SET_TYPEDOC', res?.data);
//         state.type = {"hello": "hello"};
//       } catch (error) {
//         console.log(error);
//       }
//     //console.log(res?.data)
//     //console.log(`${res?.data.keys()}`);
//     // res && res.data = res?.data
//     //for (const key in res?.data) {
//       //console.log(`${res?.data[key]}`);
//       //this.t = `${res?.data[key]}`;
//       //console.log(`${key}`);
//       //console.log(`${res?.data[key].view}`);
//       // for(const x in res?.data[key])
//       // {
//       //   //console.log(x);
        
//       //   if(x == 'view')
//       //   {
//       //this.tableTypeDoc.push(`${res?.data[key].view}`);
//       //   }
//       //}
    
//       //console.log(`${res?.data[key].view}`);
//       //this.tableTypeDoc.push(`${res?.data[key]}`);
    
//     //this.main_data = res?.data;
//     // console.log(this.main_data.keys());
//   },
};

export default actions;
