import { MutationTree } from "vuex";
import {  TemplateState } from "./state";

const mutations: MutationTree<TemplateState> = {
  // resetState(state) {
  //   state.test = '';
  // },
  // setTest(state, test: string) {
  //   state.test = test;
  // },
  SET_TYPEDOC(state, type: any) {
    state.type = type;
  }
};

export default mutations;
