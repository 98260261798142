// import { key, storage } from "@/lib/storage";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import i18n from '../i18n'

const routes: RouteRecordRaw[] = [

  /*<-------------------------------------------------------LOGIN VIEW------------------------------------------------------->*/
  {
    path: "/login",
    component: () => import("@/views/layouts/LoginLayout.vue"),
    children: [
      {
        path: "",
        meta: { title: "Login Page" },
        name: "Login",
        component: () => import("@/views/pages/login/Login.vue"),
      },
    ],
  },

  /*<-------------------------------------------------------AFTER LOGIN VIEW------------------------------------------------------->*/
  
  {
    path: "/contact",
    component: () => import("../views/pages/contact/contact.vue"),
  },

  {
    // redirect to this route if user had loggin
    // redirect by route name not path because this has the same path with index
    name: "Home",
    path: "/:lang?",
    component: () => import("../views/pages/dashboard/DashBoard.vue"),
    // name: "AfterLoginView",
    // redirect: "/home",
    // component: () => import("@/views/layouts/AfterLoginLayout.vue"),
    // // beforeEnter: (to, from, next) => {
    // //   const localStorage = storage.getLocalStorage();
    // //   const sessionId = localStorage.get(key.sessionId);
    // //   if (!sessionId) window.location.href = "/login";
    // //   next();
    // // },
    // children: [
    //   //after login child page
    //   {
    //     path: "/home",
    //     name: "Dashboard",
    //     component: () => import("../views/pages/dashboard/DashBoard.vue"),
    //     //component: () => import("../views/pages/dashboard/dasboard_main.vue"),
    //   },
    // ],
    
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;