export default {
  "selectedLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語 "])},
  "title": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルを下のボックスにドラッグ&ドロップする、またはアップロードするフォルダを"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["してください。"])}
  },
  "warnFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここにファイルをアップロードする"])},
  "warnDrive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["またはGoogleドライブのリンクを下のボックスに入力する必要があります。"])},
  "opDrive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["またはGoogle ドライブのリンク"])},
  "upDrive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google ドライブフォルダのURL"])},
  "btnProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データの処理"])},
  "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["処理済みデータ"])},
  "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像 "])},
  "loadData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抽出するデータ"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイプ："])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダウンロード"])},
  "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リセット"])},
  "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["©2021 The copyright belongs to AI Power"])}
}