
import { computed, defineComponent, provide, reactive, ref } from "vue";
import Enum from "@/models/Enum"

export default defineComponent({
  name: "App",
  setup() {
    const state = reactive({
      isMobile: false,
      theme: Enum.Theme.LIGHT,
      lang: '',
    });
    const app = ref(null);
    provide(
      "isMobile",
      computed(() => state.isMobile)
    );
    provide(
      "theme",
      computed(() => state.theme)
    );


    const onResize = () => {
      state.isMobile = window.innerWidth < 960;
    };

    // changeLangufage = () => {}

    return {
      app,
      onResize,
    };
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
});
/**
 * @vuese
 * @group Global
 * Root vue file
 */
