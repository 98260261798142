import { MutationTree } from "vuex";
import {  LoadingState } from "./state";

const mutations: MutationTree<LoadingState> = {
  SET_LOADING(state, loadingPercent: number) {
    state.loadingPercent = loadingPercent;
  }
};

export default mutations;
