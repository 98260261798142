import { GetterTree } from "vuex";
import { RootState } from "../rootState";
import {
    LoadingState,
} from "./state";

const getters: GetterTree<LoadingState, RootState> = {
  getLoadedProgress(state: LoadingState): number {
    return state.loadingPercent;
  },
};

export default getters;