export default {
  "selectedLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngôn ngữ "])},
  "title": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kéo thả file vào ô bên dưới hoặc"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chọn"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["từ thư mục để tải lên."])}
  },
  "warnFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn cần tải File lên đây hoặc"])},
  "warnDrive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập vào đây đường dẫn Google Drive bên dưới"])},
  "opDrive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoặc link google drive"])},
  "upDrive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ULR Google Drive Folder"])},
  "btnProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xử lí dữ Liệu"])},
  "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dữ liệu đã xử lý"])},
  "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hình ảnh "])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại:"])},
  "loadData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dữ liệu được trích xuất"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tải file "])},
  "btnReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["©2021 The copyright belongs to AI Power"])}
}